//  export const baseUrl = "http://192.168.1.65:2895/api";
export const baseUrl = "https://api.assetplus.live/api";
export const baseUrlAdmin = "https://api.assetplus.live/api/admin";
// export const baseUrlAdmin = "http://192.168.1.65:2895/api/admin";

export const copyUrl = "https://assetplus.live/register/referral/";




// const buyUrl = process.env.REACT_APP_buyUrl;

// export { buyUrl };
